import React from 'react';
import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import B2BCourseTemplateModel from '@uz/unitz-models/B2BCourseTemplateModel';
import useRoute from '@vl/hooks/useGbRoute';
import _ from 'lodash';
import { v4 as getUUid } from 'uuid';
import { notification } from 'antd';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const account_id = ctx.apply('accountModel.getAccountId');
              const user_id = ctx.apply('currentUserModel.getUserId');
              const route = useRoute();
              const form = useFormik({
                initialValues: {
                  user_id,
                  name: '',
                  photo_url: [],
                  description: '',
                  link_urls: [
                    {
                      id: 1,
                      content: '',
                    },
                  ],
                  materials: [],
                  multiple_quantity: 0,
                  lessons: [
                    {
                      name: '',
                      id: getUUid(),
                      // photo_url: [],
                      description: '',
                      link_urls: [
                        {
                          content: '',
                        },
                      ],
                      materials: [],
                    },
                  ],
                },
                onSubmit: async (values, actions) => {
                  try {
                    const course_template = await B2BCourseTemplateModel.createCourseTemplate({
                      object: {
                        user_id,
                        account_id,
                        name: values.name,
                        photo_url: _.get(values, 'photo_url.0.url'),
                        description: values.description,
                        link_urls: `{${_.map(_.castArray(values.link_urls, []), 'content').join(',')}}`,
                        materials: _.castArray(values.materials, []),
                      },
                    });
                    if (course_template) {
                      const course_template_id = _.get(course_template, 'insert_b2b_course_template_one.id', '');
                      const insert_lessons = await B2BCourseTemplateModel.insertLessons({
                        lessons: _.map(values.lessons, (item) => {
                          return {
                            ..._.omit(item, ['link_urls', 'materials', 'id', 'photo_url']),
                            user_id,
                            account_id,
                            template_id: course_template_id,
                            // photo_url: _.get(item, 'photo_url.0.url'),
                            link_urls: `{${_.map(_.castArray(item.link_urls, []), 'content').join(',')}}`,
                            materials: _.castArray(item.materials, []),
                          };
                        }),
                      });
                      if (insert_lessons) {
                        route.navigate(
                          ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplateCreated', {
                            id: course_template_id,
                          })
                        );
                      }
                    }
                    if (!course_template) {
                      notification.error({
                        placement: 'bottomRight',
                        message: ctx.apply('i18n.t', 'ZoomToolError.CourseTemplate.create.fail'),
                      });
                    }
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({
                  name: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  description: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  lessons: Yup.array().of(Yup.object().shape({})),
                }),
              });

              _.assign(form, {
                canSubmit: form.isValid && form.dirty,
                array: {
                  add: (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const valueToInsert = _.clone(_.last(fieldArray));
                    const cleanItem = (val) => {
                      let rtn = '';
                      if (_.isPlainObject(val)) {
                        rtn = _.clone(val);
                        for (const key in valueToInsert) {
                          rtn[key] = cleanItem(valueToInsert[key]);
                        }
                      } else if (_.isArray(val)) {
                        rtn = [];
                      }
                      return rtn;
                    };

                    let newItemVal = cleanItem(valueToInsert);
                    if (_.isEmpty(valueToInsert)) {
                      newItemVal = {
                        name: '',
                        id: getUUid(),
                        // photo_url: [],
                        description: '',
                        link_urls: [
                          {
                            content: '',
                          },
                        ],
                        materials: [],
                      };
                    } else {
                      newItemVal.id = valueToInsert.id + 1;
                    }
                    form.setFieldValue(field, [...fieldArray, newItemVal]);
                  },
                  addMultiple: async (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const quantity = await _.parseInt(_.get(form, 'values.multiple_quantity', 0));

                    const array = [...fieldArray];
                    const newArr = [];
                    _.range(quantity).map((item) => {
                      return newArr.push({
                        name: '',
                        id: getUUid() + item,
                        // photo_url: [],
                        description: '',
                        link_urls: [
                          {
                            content: '',
                          },
                        ],
                        materials: [],
                      });
                    });
                    const data = _.concat(array, newArr);

                    form.setFieldValue(field, data);
                  },

                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },
                },
                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
